import mainStyles from '@/styles/app.css?inline';

export const Styles = () => {
  const componentStyles = Object.values(import.meta.glob(
    '@/components/**/*.css',
    { import: 'default', query: '?inline', eager: true },
  ));

  return (
    <style>
      {mainStyles}
      {componentStyles.join('\n')}
    </style>
  );
};
